import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SocialShare from "../components/social-share-links"

import { MetaData } from "../components/meta"
import Helmet from 'react-helmet'

class Poema extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const post = this.props.data.ghostPost
    return (
      <>
      <MetaData data={this.props.data} location={this.props.location} type="article" />
      <Helmet>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>

        <Layout>
          <div className="row pt-3 justify-content-center">
            <div className="col-8"></div>
            <div className="col-md-8 ">
              <h2>{post.title}</h2>
              <div className="d-flex justify-content-end">
                <SocialShare
                  href={this.props.location.href}
                  title={post.title}
                />
              </div>

              <section
                className="load-external-scripts"
                dangerouslySetInnerHTML={{ __html: post.html }}
              ></section>
              <hr />
              <div className="d-flex justify-content-end">
                <SocialShare
                  href={this.props.location.href}
                  title={post.title}
                />
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default Poema

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`
